<template>
  <div class="container">
    <div v-if="loadingPage" class="d-flex justify-content-center mt-5 mb-5">
      <Preloader/>
    </div>
    <div v-else>
      <div v-if="success">
        <div v-if="is_practice===0">
          <h4 class="mt-4 text-center">Журнал учета успеваемости ({{ ratingTypes[type] }})</h4>
          <h5 class="mt-4 text-danger" v-if="isClosed">Ведомость закрыта</h5>
          <div class="mt-4 mb-2">
            <div class="row">
              <div class="col-md-2">
                Название дисциплины
              </div>
              <div class="col-md-10">
                {{ journalRating?.discipline?.name }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                Курс
              </div>
              <div class="col-md-10">
                {{ journalRating?.discipline?.study_course }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                Семестер
              </div>
              <div class="col-md-10">
                {{ journalRating?.discipline?.semester }}
              </div>
            </div>

            <div v-if="journalRating?.teacher?.lecture_user_id==journalRating?.teacher?.seminar_user_id">
              <div class="row">
                <div class="col-md-2">
                  Преподаватель
                </div>
                <div class="col-md-10">
                  {{ journalRating?.teacher?.lecture_lastname }}
                  {{ journalRating?.teacher?.lecture_firstname }}
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-md-2">
                  Лектор
                </div>
                <div class="col-md-10">
                  {{ journalRating?.teacher?.lecture_lastname }}
                  {{ journalRating?.teacher?.lecture_firstname }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  Практик
                </div>
                <div class="col-md-10">
                  {{ journalRating?.teacher?.seminar_lastname }}
                  {{ journalRating?.teacher?.seminar_firstname }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                Moodle курс
              </div>
              <div class="col-md-10">
                <a :href="'https://moodle.uib.kz/course/view.php?id='+ journalRating?.discipline?.mdl_course_id"
                   target="_blank">
                  {{ journalRating?.discipline?.course_name }}
                </a>
              </div>
            </div>
            <div class="row mt-2 mb-4">
              <div class="col-md-2">
                Группы
              </div>
              <div class="col-md-6">
                <select class="form-select" v-model="selectedGroup">
                  <option v-for="(group, groupIndex) in [{group_id: 0, group_name: 'Все'}, ...groups]"
                          :key="groupIndex" :value="group.group_id">
                    {{ group.group_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- print transcript section -->
          <div v-if="selectedGroup && selectable_group_id === 'null'" class="my-2">

            <a class="btn btn-primary" target="_blank" role="button"
               :href="`https://back.uib.kz/student/transcript/print-statement?group_id=${selectedGroup}&mdl_course_id=${mdl_course_id}`">
              Распечатать
            </a>

          </div>

          <div v-if="selectable_group_id > 0" class="my-2">
            <a class="btn btn-primary" target="_blank" role="button"
               :href="`https://back.uib.kz/student/transcript/print-statement?group_id=null&mdl_course_id=${mdl_course_id}`">
              Распечатать
            </a>

          </div>


          <div class="mt-4">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">Группа</th>
                <th scope="col">Баркод</th>
                <th scope="col">Ф.И.О</th>
                <th scope="col">{{ ratingTypes[type] }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(student, studentIndex) in studentsFilter" :key="studentIndex">
                <th scope="row">{{ studentIndex + 1 }}</th>
                <td>{{ student?.group_name }}</td>
                <td>{{ student?.barcode }}</td>
                <td>{{ student?.last_name + ' ' + student?.first_name }}</td>
                <td>{{ student[type] ? student[type] : student['score'] }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div v-else-if="is_practice===1">
          <h4 class="mt-4 text-center">Журнал учета успеваемости ({{ ratingTypes[type] }})</h4>
          <h5 class="mt-4 text-danger" v-if="isClosed">Ведомость закрыта</h5>
          <div class="mt-4 mb-2">
            <div class="row">
              <div class="col-md-2">
                Название дисциплины
              </div>
              <div class="col-md-10">
                {{ studentsFilter.discipline_name }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                Курс
              </div>
              <div class="col-md-10">
                {{ studentsFilter.study_course }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                Семестер
              </div>
              <div class="col-md-10">
                {{ studentsFilter.semester }}
              </div>
            </div>

            <div v-if="journalRating?.teacher?.lecture_user_id==journalRating?.teacher?.seminar_user_id">
              <div class="row">
                <div class="col-md-2">
                  Преподаватель
                </div>
                <div class="col-md-10">
                  {{ studentsFilter.pps_firstname }}
                  {{ studentsFilter.pps_lastname }}
                </div>
              </div>
            </div>
            <!--            <div class="row mt-2 mb-4">-->
            <!--              <div class="col-md-2">-->
            <!--                Группы-->
            <!--              </div>-->
            <!--              <div class="col-md-6">-->
            <!--                <select class="form-select" v-model="selectedGroup">-->
            <!--                  <option v-for="(group, groupIndex) in [{group_id: 0, group_name: 'Все'}, ...groups]"-->
            <!--                          :key="groupIndex" :value="group.group_id">-->
            <!--                    {{ group.group_name }}-->
            <!--                  </option>-->
            <!--                </select>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <!-- print transcript section -->
          <div v-if="selectedGroup && selectable_group_id === 'null'" class="my-2">

            <a class="btn btn-primary" target="_blank" role="button"
               :href="`https://back.uib.kz/student/transcript/print-statement?group_id=${selectedGroup}&mdl_course_id=${mdl_course_id}`">
              Распечатать
            </a>

          </div>

          <a class="btn btn-primary" target="_blank" role="button"
             :href="`https://back.uib.kz/student/transcript/print-practice-statement?formation_education_program_id=${formation_education_program_id}`">
            Распечатать
          </a>

          <div class="mt-4">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">Баркод</th>
                <th scope="col">Ф.И.О</th>
                <th scope="col">{{ ratingTypes[type] }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(student, studentIndex) in studentsFilter.students" :key="studentIndex">
                <th scope="row">{{ studentIndex + 1 }}</th>
                <td>{{ student?.barcode }}</td>
                <td>{{ student?.last_name + ' ' + student?.first_name }}</td>
                <td>{{ student[type] ? student[type] : student['score'] }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h4 v-else class="text-center mt-4">Курс не найден</h4>
      </div>
    </div>
  </div>

</template>

<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "StatementCourseInfo",
  data() {
    return {
      mdl_course_id: +this.$route.query.mdl_course_id || 0,
      formation_education_program_id: +this.$route.query.formation_education_program_id || 0,
      is_practice: +this.$route.query.is_practice || 0,
      //urlCourseId: +this.$route.query.course_id || 0,
      type: this.$route.query.type || '',
      selectable_group_id: this.$route.query.selectable_group_id,
      loadingPage: true,
      success: false,
      closeStatus: false,
      ratingTypes: {
        vsk1: 'ВСК 1',
        vsk2: 'ВСК 2',
        exam: 'Экзамен',
        total: 'Итоговый'
      },
      groups: [],
      selectedGroup: 0
    }
  },
  computed: {
    ...mapState('journalRating', ['journalRating']),
    isClosed() {
      if (Array.isArray(this.journalRating.students)) {
        return this.journalRating.students.some(i => i['status_' + this.type] == 1)
      } else {
        return false
      }
    },
    isLectureTeacher() {
      return this.journalRating?.teacher?.lecture_user_id == getCookie('USER_ID')
    },
    studentsFilter() {
      if (this.selectedGroup) {
        return this.journalRating.students.filter(i => i.group_id == this.selectedGroup)
      }
      return this.journalRating.students
    }
  },

  methods: {
    ...mapActions('journalRating', [
      'GET_STATEMENT_BY_MDL_COURSE_ID',
      'GET_TEACHER_BY_MDL_COURSE_ID',
      'GET_DISCIPLINE_BY_MDL_COURSE_ID',
      'GET_PRACTICE_RATINGS'
    ]),
  },
  async mounted() {
    if (this.is_practice == 1) {
      await this.GET_PRACTICE_RATINGS(this.formation_education_program_id)
      this.success = true
    } else {
      if (this.ratingTypes[this.type]) {
        await this.GET_DISCIPLINE_BY_MDL_COURSE_ID(this.mdl_course_id)
        if (this.journalRating?.discipline?.mdl_course_id) {
          await this.GET_STATEMENT_BY_MDL_COURSE_ID(this.mdl_course_id)
          await this.GET_TEACHER_BY_MDL_COURSE_ID(this.mdl_course_id)
          const groupsObj = {}
          this.journalRating.students.forEach(i => {
            groupsObj[i.group_id] = i
          })
          this.groups = Object.values(groupsObj).map(i => ({
            group_id: i.group_id,
            group_name: i.group_name
          }))
          console.log(this.groups)
          this.success = true
        }
      }
    }

    this.loadingPage = false
  }

}
</script>

<style scoped>

</style>